import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from "./config.dist"
import router from './router'
//import i18n from './i18n'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		accessToken: localStorage.getItem('accessToken'),
		refreshToken: localStorage.getItem('refreshToken'),
		centerInfo: JSON.parse(localStorage.getItem('centerInfo')) || {},
		companyInfo: JSON.parse(localStorage.getItem('companyInfo')) || {},
		alerts:[],
		language: localStorage.getItem('language') || 'ru',
		currentRole: localStorage.getItem('currentRole') || ''
	},

	mutations: {
		login(state, response) {
			state.accessToken = response.data.token;
			state.refreshToken = response.data.refresh_token;
			localStorage.setItem('accessToken', state.accessToken);
			axios.defaults.headers.common['X-Auth'] = state.accessToken;
		},
		setLanguage(state, lang) {
			localStorage.setItem('language', lang);
			state.language = lang;
			//i18n.locale = lang;
		},
		setCurrentRole(state, role) {
			localStorage.setItem('currentRole', role);
			state.currentRole = role;
		},
		setCenterInfo(state, data) {
			localStorage.setItem('centerInfo', JSON.stringify(data));
			state.centerInfo = data;
		},
		setCompanyInfo(state, data) {
			localStorage.setItem('companyInfo', JSON.stringify(data));
			state.companyInfo = data;
		},
		alert(state, {alert, adding}) {
            adding ? state.alerts.push(alert)
				: state.alerts.shift()
		},
		logout(state) {
			state.accessToken = '';
			state.refreshToken = '';
			state.centerInfo = {};
			state.currentRole = '';
			localStorage.removeItem('centerInfo');
			localStorage.removeItem('companyInfo');
			localStorage.removeItem('currentRole');
			localStorage.removeItem('accessToken');
			localStorage.removeItem('refreshToken');
			delete axios.defaults.headers.common['X-Auth'];
		}
	},

	actions: {
		setCurrentRole({commit, dispatch}, role) {
			commit('setCurrentRole', role);
			dispatch('changeBaseUrl');
		},
		changeBaseUrl({getters, state}) {
			axios.defaults.baseURL = getters.getBaseUrl+getters.getApiVersion+(state.currentRole ? '/'+state.language+'/'+state.currentRole : '');
		},
		alertAdd({commit}, alert) {
			commit('alert', { alert, adding: true});
			setTimeout(() => commit('alert', { alert, adding: false }), 5000);
		},
		login({commit}, response) {
			return new Promise((resolve) => {
				commit('login', response);
				resolve();
			})
		},
		logout({commit, dispatch}) {
			let history = (router.history.pending ?? router.history.current);
			commit('logout');
			dispatch('changeBaseUrl');
			let url = '/portal/#/login'+(history.matched[0] ? '/'+history.matched[0].name : '')+(history.fullPath != '/' ? '?url='+encodeURIComponent(history.fullPath) : '');

			console.log(url);
			//router.go(url);
			// console.log(user, router.history);
			// throw new Error();
			//location.replace(url);
			location.href = url;
			location.reload();
			//location.href = '/portal/#/login'+(history.matched[0] ? '/'+history.matched[0].name : '')+(history.fullPath != '/' ? '?url='+encodeURIComponent(history.fullPath) : '');
		}
	},

	getters: {
		getBaseUrl() {
			return config.API_URL;
		},
		getApiVersion() {
			return config.API_VERSION;
		},
		getAccessToken(state) {
			return state.accessToken;
		},
		getRefreshToken(state) {
			return state.refreshToken;
		},
		getCurrentRole(state) {
			return state.currentRole;
		},
		getCenterInfo(state) {
			return state.centerInfo;
		},
		getCompanyInfo(state) {
			return state.companyInfo;
		},
		getLanguage(state) {
			return state.language;
		}
	}

});